import React, { useState, useEffect, useContext } from 'react';
import { Container, Form, Title, FormSection, Label, Input, Select, Button, ErrorMessage } from './styled';
import { Create } from '../../../service/officer';
import { ReadAll } from '../../../service/patrols';
import { toast } from 'react-toastify';
import Wrapper from '../Wrapper';
import { AuthContext } from '../../../context/contextGlobal/authContext';

const RegisterOfficer = () => {
    const { setModal } = useContext(AuthContext);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [patrols, setPatrols] = useState([]);
    const loadPatrols = async () => {
        try {
            const response = await ReadAll();
            if (response.data) {
                setPatrols(response.data);
            }
        } catch (err) {
            toast.error('Erro ao carregar batalhões');
        }
    };

    const [formData, setFormData] = useState({
        fullName: '',
        funcional: '',
        patrolId: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            const response = await Create(formData);

            if (response.error) {
                throw new Error(response.message || 'Erro ao cadastrar policial');
            }

            toast.success('Policial pré-cadastrado com sucesso! Aguardando primeiro acesso.');
            setFormData({ fullName: '', funcional: '', patrolId: '' });
            setModal(null);
        } catch (err) {
            setError('Erro ao cadastrar policial. Verifique os dados e tente novamente.');
            toast.error(err.message || 'Erro ao cadastrar policial');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadPatrols();
    }, []);

    return (
        <Wrapper>
            <Form onSubmit={handleSubmit}>
                <Title>Pré-Cadastro de Policial</Title>

                <FormSection>
                    <Label>Nome Completo</Label>
                    <Input
                        type="text"
                        name="fullName"
                        placeholder="Digite o nome completo"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                    />
                </FormSection>

                <FormSection>
                    <Label>Funcional</Label>
                    <Input
                        type="text"
                        name="funcional"
                        placeholder="Digite o número funcional"
                        value={formData.funcional}
                        onChange={handleChange}
                        required
                    />
                </FormSection>

                <FormSection>
                    <Label>Batalhão</Label>
                    <Select
                        name="patrolId"
                        value={formData.patrolId}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Selecione um batalhão</option>
                        {patrols.map(patrol => (
                            <option key={patrol.id} value={patrol.id}>
                                {patrol.attributes.name}
                            </option>
                        ))}
                    </Select>
                </FormSection>

                {error && <ErrorMessage>{error}</ErrorMessage>}

                <Button type="submit" disabled={loading}>
                    {loading ? 'Cadastrando...' : 'Pré-Cadastrar'}
                </Button>
            </Form>
        </Wrapper>
    );
};

export default RegisterOfficer; 