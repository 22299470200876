import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../context/contextGlobal/authContext';

import { Container, Form, FormRow } from './styled';
import { Button, TextField, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import Core from '../Core';
import { ReadAll } from '../../../service/patrols';
import { Update } from '../../../service/officer';
import Wrapper from '../Wrapper';

export default function EditOfficer() {
    const { setModal, modal } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [patrols, setPatrols] = useState([]);
    const [formData, setFormData] = useState({
        id: modal?.data?.id || '',
        funcional: modal?.data?.funcional || '',
        status: modal?.data?.status || 'active',
        fullName: modal?.data?.user?.name || '',
    });

    const statusOptions = [
        { value: 'active', label: 'Ativo' },
        { value: 'inactive', label: 'Inativo' },
        { value: 'onLeave', label: 'De Licença' }
    ];

    useEffect(() => {
        console.log('🔄 Modal data mudou:', modal?.data);
        if (modal?.data) {
            setFormData({
                id: modal.data.id || '',
                funcional: modal.data.funcional || '',
                status: modal.data.status || 'active',
                fullName: modal.data.user?.name || '',
            });
        }
    }, [modal?.data]);

    useEffect(() => {
        const loadPatrols = async () => {
            try {
                const response = await ReadAll();
                if (response?.data) {
                    const formattedPatrols = response.data.map(patrol => ({
                        id: patrol.id,
                        ...patrol.attributes
                    }));
                    setPatrols(formattedPatrols);
                    console.log('📋 Batalhões carregados:', formattedPatrols);
                }
            } catch (error) {
                console.error('❌ Erro ao carregar batalhões:', error);
                toast.error('Erro ao carregar batalhões');
            }
        };

        loadPatrols();
    }, []);

    const handleChange = (field) => (event) => {
        event.preventDefault();
        event.stopPropagation();
        console.log('🔄 Campo alterado:', field, 'Valor:', event.target.value);
        setFormData(prev => ({
            ...prev,
            [field]: event.target.value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);
        console.log('📤 Enviando dados:', formData);

        try {
            const response = await Update(formData);
            
            if (response.error) {
                console.error('❌ Erro na resposta:', response.error);
                toast.error('Erro ao atualizar policial');
                return;
            }

            console.log('✅ Policial atualizado com sucesso:', response);
            toast.success('Policial atualizado com sucesso');
            setModal(null);
        } catch (error) {
            console.error('❌ Erro ao atualizar policial:', error);
            toast.error('Erro ao atualizar policial');
        } finally {
            setLoading(false);
        }
    };

    const handleSelectClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('🔍 Select clicado');
    };

    return (
        <Wrapper title='Editar Policial'>
            <Container>
                <Form onSubmit={handleSubmit} onClick={e => e.stopPropagation()}>
                    <FormRow>
                        <TextField
                            label="Nome Completo"
                            value={formData.fullName}
                            onChange={handleChange('fullName')}
                            fullWidth
                            required
                            onClick={e => e.stopPropagation()}
                        />
                    </FormRow>

                    <FormRow>
                        <TextField
                            label="Funcional"
                            value={formData.funcional}
                            onChange={handleChange('funcional')}
                            fullWidth
                            required
                            onClick={e => e.stopPropagation()}
                        />
                    </FormRow>

                    <FormRow>
                        <TextField
                            select
                            label="Status"
                            value={formData.status}
                            onChange={handleChange('status')}
                            fullWidth
                            required
                            onClick={handleSelectClick}
                            SelectProps={{
                                MenuProps: {
                                    onClick: e => e.stopPropagation(),
                                    onClose: e => {
                                        if (e) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }
                                    }
                                }
                            }}
                        >
                            {statusOptions.map(option => (
                                <MenuItem 
                                    key={option.value} 
                                    value={option.value}
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormRow>

                    <FormRow>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                            disabled={loading}
                        >
                            Salvar Alterações
                        </Button>
                    </FormRow>
                </Form>
            </Container>
        </Wrapper>
    );
} 