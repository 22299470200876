/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/contextGlobal/authContext";
import { Wrapper, PageTitle, ContentContainer, ChartGrid, ChartPaper, WrapperTablles, TableTitle, TitleContainer, TitleWrapper, FullscreenButton, SectionFullscreen } from "./styled";
import { Read } from "../../../service/patrols";
import { GetAll as getOfficers } from "../../../service/officer";
import { getLastLocations } from "../../../service/officer-location";
import StatisticCard from '../../../components/Cards/StatisticCard';
import ModernTable from '../../../components/Tables/ModernTable';
import StatusButton from '../../../components/Buttons/StatusButton';
import VisitStatusButton from '../../../components/Buttons/VisitStatusButton';
import ModernMap from '../../../components/Maps/ModernMap';
import { useProperties } from '../../../context/PropertiesContext';
import React from 'react';
import { Button } from '@mui/material';
import BarChart from '../../../components/Charts/BarChart';
import DonutChart from '../../../components/Charts/DonutChart';
import { Info, PoliceCar, ArrowsOut, ArrowsIn } from '@phosphor-icons/react';
import PatrolStatisticCard from '../../../components/Cards/PatrolStatisticCard';
import Carousel from '../../../components/Cards/PatrolStatisticCard/Carousel';
import { getAllOccurrences, getUnassignedOccurrences } from '../../../service/occurrences';
import OccurrenceTypeStats from '../../../components/OccurrenceTypeStats';
import { PatrolStatsGrid } from './styled';
import { GetPropertiesInProgress, GetOccurrencesInProgress } from "../../../service/officer-assignments";

const HomePage = () => {
    const {
        setPatrols,
        patrols,
        selectedProduct,
        setSelectedProduct,
        setMapCenter,
        setModal
    } = useContext(AuthContext);

    const navigation = useNavigate();
    const { fetchProperties, processedData, filteredData, totals } = useProperties();

    // Estado para controlar loading inicial
    const [isLoading, setIsLoading] = useState(true);

    // Filtra propriedades com status "Aguardando"
    const aguardandoData = React.useMemo(() => {
        return (filteredData?.length > 0 ? filteredData : processedData)?.filter(
            property => property.status?.toLowerCase() === 'aguardando'
        ) || [];
    }, [filteredData, processedData]);

    // Adicione este novo estado
    const [patrolStats, setPatrolStats] = useState({});

    // Adicione este estado para controlar o fullscreen
    const [isFullScreen, setIsFullScreen] = useState(false);

    // Adicione este estado para armazenar os oficiais
    const [officers, setOfficers] = useState([]);
    const [officerLocations, setOfficerLocations] = useState([]);

    // Adicione este estado para controlar o fullscreen de cada seção
    const [fullscreenSections, setFullscreenSections] = useState({
        propertyCards: false,      // Cards de estatísticas de propriedades
        tables: false,             // Tabelas de Aguardando e Ocorrências
        propertyList: false,       // Lista completa de propriedades
        map: false,                // Mapa de localização
        patrolStats: false,        // Estatísticas por Batalhão
        occurrenceAnalysis: false, // Gráficos de análise de ocorrências
        occurrenceStats: false,   // Totalizadores por tipo de ocorrência
        inProgress: false         // Nova seção de visitas em andamento
    });

    // Adicione este estado para armazenar as propriedades em andamento
    const [propertiesInProgress, setPropertiesInProgress] = useState([]);

    // Adicione este estado
    const [occurrencesInProgress, setOccurrencesInProgress] = useState([]);

    // Adicione este novo array de colunas após as outras definições de colunas
    const inProgressColumns = [
        {
            field: 'name',
            headerName: 'Nome da Propriedade',
            sortable: true,
            renderCell: (rowData) => rowData.name || 'Não informado'
        },
        {
            field: 'proprietario',
            headerName: 'Proprietário',
            renderCell: (rowData) => rowData.user?.username || 'Sem proprietário',
            sortable: true
        },
        {
            field: 'officer',
            headerName: 'Policial Responsável',
            renderCell: (rowData) => rowData.currentAssignment?.officer?.user?.name || 'Não atribuído',
            sortable: true
        },
        {
            field: 'street',
            headerName: 'Endereço',
            renderCell: (rowData) => rowData.street || 'Não informado',
            sortable: true
        },
        {
            field: 'municipality',
            headerName: 'Município',
            renderCell: (rowData) => rowData.municipality || 'Não informado',
            sortable: true
        },
        {
            field: 'estate',
            headerName: 'Estado',
            renderCell: (rowData) => rowData.state || 'Não informado',
            sortable: true
        },
        {
            field: 'cep',
            headerName: 'CEP',
            renderCell: (rowData) => rowData.cep || 'Não informado',
            sortable: true
        },
        {
            field: 'patrol',
            headerName: 'Batalhão',
            renderCell: (rowData) => rowData.patrol?.name || 'Sem Batalhão',
            sortable: true
        },
        {
            field: 'assignedAt',
            headerName: 'Data de Atribuição',
            renderCell: (rowData) => {
                const date = new Date(rowData.currentAssignment?.assignedAt);
                return new Intl.DateTimeFormat('pt-BR', {
                    dateStyle: 'short',
                    timeStyle: 'short'
                }).format(date);
            },
            sortable: true
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (rowData) => (
                <div style={{ padding: '6px 16px', borderRadius: '4px', backgroundColor: '#60E77B', color: '#000', textAlign: 'center' }}>
                    Em Andamento
                </div>
            ),
            sortable: true
        }
    ];

    // Adicione as colunas para a tabela de ocorrências em andamento
    const occurrencesInProgressColumns = [
        {
            field: 'occurrence_type',
            headerName: 'Tipo de Ocorrência',
            renderCell: (rowData) => translateOccurrenceType(rowData.occurrence_type) || 'Não informado',
            sortable: true
        },
        {
            field: 'police_report_number',
            headerName: 'Número B.O.',
            renderCell: (rowData) => rowData.police_report_number || 'Não informado',
            sortable: true
        },
        {
            field: 'occurrence_date',
            headerName: 'Data',
            renderCell: (rowData) => {
                try {
                    const date = rowData.occurrence_date;
                    return date ? new Intl.DateTimeFormat('pt-BR').format(new Date(date)) : 'Não informado';
                } catch (error) {
                    console.error('Erro ao formatar data:', error);
                    return 'Data inválida';
                }
            },
            sortable: true
        },
        {
            field: 'occurrence_time',
            headerName: 'Hora',
            renderCell: (rowData) => {
                try {
                    const time = rowData.occurrence_time;
                    return time ? time.substring(0, 5) : 'Não informado'; // Pega só HH:mm
                } catch (error) {
                    console.error('Erro ao formatar hora:', error);
                    return 'Hora inválida';
                }
            },
            sortable: true
        },
        {
            field: 'property',
            headerName: 'Propriedade',
            renderCell: (rowData) => rowData.property?.name || 'Não informado',
            sortable: true
        },
        {
            field: 'municipality',
            headerName: 'Município',
            sortable: true,
            renderCell: (rowData) => rowData.municipality || 'Não informado'
        },
        {
            field: 'patrol',
            headerName: 'Batalhão',
            renderCell: (rowData) => rowData.property?.patrol?.name || 'Sem Batalhão',
            sortable: true
        },
        {
            field: 'officer',
            headerName: 'Policial Responsável',
            renderCell: (rowData) => rowData.currentAssignment?.officer?.user?.name || 'Não atribuído',
            sortable: true
        },
        {
            field: 'assignedAt',
            headerName: 'Data de Atribuição',
            renderCell: (rowData) => {
                try {
                    const date = new Date(rowData.currentAssignment?.assignedAt);
                    return new Intl.DateTimeFormat('pt-BR', {
                        dateStyle: 'short',
                        timeStyle: 'short'
                    }).format(date);
                } catch (error) {
                    return 'Data inválida';
                }
            },
            sortable: true
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (rowData) => (
                <div style={{ 
                    padding: '6px 16px', 
                    borderRadius: '4px', 
                    backgroundColor: '#60E77B', 
                    color: '#000', 
                    textAlign: 'center' 
                }}>
                    Em Andamento
                </div>
            ),
            sortable: true
        }
    ];

    // Adicione este novo estado
    const [pendingOccurrences, setPendingOccurrences] = useState([]);

    // Adicione esta função para calcular estatísticas por batalhão
    const calculatePatrolStats = (properties) => {
        const stats = {};

        properties.forEach(property => {
            if (!property.patrol?.name) return;

            const patrolName = property.patrol.name;

            if (!stats[patrolName]) {
                stats[patrolName] = {
                    total: 0,
                    aprovadas: 0,
                    reprovadas: 0,
                    visitadas: 0,
                    aguardando: 0,
                    inativas: 0
                };
            }

            stats[patrolName].total++;

            // Incrementa os contadores baseado no status
            if (property.status?.toLowerCase() === 'ativo' ||
                property.status?.toLowerCase() === 'aprovada' ||
                property.status?.toLowerCase() === 'aprovado') {
                stats[patrolName].aprovadas++;
            }
            if (property.status?.toLowerCase() === 'reprovada' ||
                property.status?.toLowerCase() === 'reprovado') {
                stats[patrolName].reprovadas++;
            }
            if (property.status?.toLowerCase() === 'aguardando') {
                stats[patrolName].aguardando++;
            }
            if (property.status?.toLowerCase() === 'inativo' ||
                property.status?.toLowerCase() === 'inativa') {
                stats[patrolName].inativas++;
            }
            if (property.status_visit?.toLowerCase() === 'visitado' ||
                property.status_visit?.toLowerCase() === 'ativo') {
                stats[patrolName].visitadas++;
            }
        });

        return stats;
    };

    // Função para carregar dados dos oficiais e suas localizações
    const loadOfficersData = async () => {
        try {
            const [officersResponse, locationsData] = await Promise.all([
                getOfficers(),
                getLastLocations()
            ]);

            if (!officersResponse?.data) {
                console.error('❌ Dados dos oficiais inválidos');
                return;
            }

            // Extrai os dados dos oficiais do formato da API
            const officersData = officersResponse.data.map(officer => {
                try {
                    return {
                        id: officer.id,
                        ...officer.attributes,
                        name: officer.attributes.user.data.attributes.name,
                        patrol: officer.attributes.patrol.data.attributes
                    };
                } catch (error) {
                    console.error('❌ Erro ao processar oficial:', officer.id, error);
                    return null;
                }
            }).filter(Boolean);

            // Combina os dados dos policiais com suas últimas localizações
            const officersWithLocation = officersData.map(officer => {
                try {
                    const location = locationsData.find(loc => loc.officer.id === officer.id);
                    if (!location) return null;

                    return {
                        ...officer,
                        location: {
                            latitude: parseFloat(location.latitude),
                            longitude: parseFloat(location.longitude),
                            battery_level: location.battery_level,
                            is_charging: location.is_charging,
                            connection_type: location.connection_type,
                            createdAt: location.createdAt
                        },
                        lastUpdate: new Date(location.createdAt)
                    };
                } catch (error) {
                    console.error('❌ Erro ao processar localização do oficial:', officer.id, error);
                    return null;
                }
            }).filter(Boolean);

            if (officersWithLocation.length > 0) {
                setOfficers(officersWithLocation);
                setOfficerLocations(locationsData);
            }
        } catch (error) {
            console.error('❌ Erro ao carregar dados dos oficiais:', error);
        }
    };

    // Adiciona o carregamento dos oficiais na inicialização
    useEffect(() => {
        const init = async () => {
            const token = localStorage.getItem('@token');
            if (!token) {
                navigation('/');
                return;
            }

            try {
                setIsLoading(true);
                await Promise.all([
                    fetchProperties(),
                    handlePatrols(),
                    loadOfficersData()
                ]);
            } catch (error) {
                console.error('Erro na inicialização:', error);
            } finally {
                setIsLoading(false);
            }
        };

        init();

        // Atualiza as localizações a cada 30 segundos
        const interval = setInterval(loadOfficersData, 30000);
        return () => clearInterval(interval);
    }, [navigation]);

    // useEffect separado para calcular estatísticas
    useEffect(() => {
        if (processedData?.length > 0) {
            const stats = calculatePatrolStats(processedData);
            setPatrolStats(stats);
        }
    }, [processedData]);

    const [chartData, setChartData] = useState({
        bar: [],
        donut: [],
    });
    const [loading, setLoading] = useState(true);

    const loadOccurrenceData = async () => {
        try {
            setLoading(true);
            const { municipalityStats, distribution } = await getAllOccurrences();

            // Formata dados para o gráfico de barras
            const barData = [
                ['Município', 'Ocorrências'],
                ...municipalityStats.map(item => [item.municipality, item.count])
            ];

            // Formata dados para o gráfico de rosca
            const donutData = [
                ['Município', 'Porcentagem'],
                ...distribution.map(item => [item.municipality, Number(item.percentage)])
            ];

            setChartData({
                bar: barData,
                donut: donutData
            });
        } catch (error) {
            console.error('Erro ao carregar dados de ocorrências:', error);
            setModal({
                type: 'warning',
                title: 'Erro',
                text: 'Não foi possível carregar os dados de ocorrências.'
            });
        } finally {
            setLoading(false);
        }
    };

    // Adiciona o useEffect para carregar os dados das ocorrências
    useEffect(() => {
        loadOccurrenceData();
    }, []);

    // Colunas específicas para a tabela Aguardando
    const aguardandoColumns = [
        {
            field: 'name',
            headerName: 'Nome da Propriedade',
            sortable: true,
            renderCell: (rowData) => rowData.name || 'Não informado'
        },
        {
            field: 'proprietario',
            headerName: 'Nome do Proprietario',
            renderCell: (rowData) => rowData.user?.username || 'Sem proprietário',
            sortable: true
        },
        {
            field: 'property_number',
            headerName: 'Número da Propriedade',
            renderCell: (rowData) => rowData?.property_number || 'Não cadastrado',
            sortable: true
        },
        {
            field: 'area',
            headerName: 'Área',
            sortable: true,
            renderCell: (rowData) => rowData.area || 'Não informado'
        },
        {
            field: 'municipality',
            headerName: 'Endereço',
            sortable: true,
            renderCell: (rowData) => rowData.street || 'Não informado'
        },
        {
            field: 'estate',
            headerName: 'Estado',
            sortable: true,
            renderCell: (rowData) => rowData.state || 'Não informado'
        },
        {
            field: 'cep',
            headerName: 'CEP',
            sortable: true,
            renderCell: (rowData) => rowData.cep || 'Não informado'
        },
        {
            field: 'line',
            headerName: 'Linha',
            sortable: true,
            renderCell: (rowData) => rowData.line || 'Não informado'
        },
        {
            field: 'patrol',
            headerName: 'Batalhão',
            renderCell: (rowData) => rowData?.patrol?.name || 'Sem Batalhão',
            sortable: true
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (rowData) => (
                <div style={{ padding: '6px 16px', borderRadius: '4px', backgroundColor: '#E7C160', color: '#000' }}>
                    {rowData.status || 'Não definido'}
                </div>
            ),
            sortable: true
        },
        {
            field: 'status_visit',
            headerName: 'Visita',
            renderCell: (rowData) => (
                <div style={{ padding: '6px 16px', borderRadius: '4px', backgroundColor: '#60A5E7', color: '#000' }}>
                    {rowData.status_visit || 'Não visitado'}
                </div>
            ),
            sortable: true
        },
        {
            field: 'actions',
            headerName: 'Ações',
            renderCell: (rowData) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                        e.stopPropagation();
                        setModal({
                            type: 'assignOrders',
                            data: rowData
                        });
                    }}
                    sx={{
                        textTransform: 'none',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                            transform: 'translateY(-1px)',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                        }
                    }}
                >
                    Atribuir Policial
                </Button>
            ),
            sortable: false
        }
    ];

    // Colunas da tabela de propriedades
    const columns = [
        {
            field: 'name',
            headerName: 'Nome da Propriedade',
            sortable: true,
            renderCell: (rowData) => rowData.name || 'Não informado'
        },
        {
            field: 'proprietario',
            headerName: 'Nome do Proprietario',
            renderCell: (rowData) => rowData.user?.username || 'Sem proprietário',
            sortable: true
        },
        {
            field: 'property_number',
            headerName: 'Número da Propriedade',
            renderCell: (rowData) => rowData?.property_number || 'Não cadastrado',
            sortable: true
        },
        {
            field: 'area',
            headerName: 'Área',
            sortable: true,
            renderCell: (rowData) => rowData.area || 'Não informado'
        },
        {
            field: 'municipality',
            headerName: 'Endereço',
            sortable: true,
            renderCell: (rowData) => rowData.street || 'Não informado'
        },
        {
            field: 'estate',
            headerName: 'Estado',
            sortable: true,
            renderCell: (rowData) => rowData.state || 'Não informado'
        },
        {
            field: 'cep',
            headerName: 'CEP',
            sortable: true,
            renderCell: (rowData) => rowData.cep || 'Não informado'
        },
        {
            field: 'line',
            headerName: 'Linha',
            sortable: true,
            renderCell: (rowData) => rowData.line || 'Não informado'
        },
        {
            field: 'patrol',
            headerName: 'Batalhão',
            renderCell: (rowData) => rowData?.patrol?.name || 'Sem Batalhão',
            sortable: true
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (rowData) => (
                <StatusButton status={rowData.status} rowData={rowData} />
            ),
            sortable: true
        },
        {
            field: 'status_visit',
            headerName: 'Visita',
            renderCell: (rowData) => (
                <VisitStatusButton status={rowData.status_visit} propertyId={rowData.id} />
            ),
            sortable: true
        },
        {
            field: 'actions',
            headerName: 'Ações',
            renderCell: (rowData) => {
                return (
                    <Button
                        variant="contained"
                        color="info"
                        startIcon={<Info weight="duotone" />}
                        onClick={(e) => {
                            e.stopPropagation();
                            setModal({
                                type: 'propertyInfo',
                                data: rowData
                            });
                        }}
                        sx={{
                            textTransform: 'none',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                            '&:hover': {
                                transform: 'translateY(-1px)',
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                            }
                        }}
                    >
                        Informações
                    </Button>
                )
            },
            sortable: false
        }
    ];

    const translateOccurrenceType = (type) => {
        const translations = {
            'personal': 'Pessoal',
            'other': 'Outro',
            'suspicious': 'Suspeito',
            'obstruction': 'Obstrução'
        };
        return translations[type] || type;
    };

    // Colunas específicas para a tabela de ocorrências
    const occurrenceColumns = [
        {
            field: 'occurrence_type',
            headerName: 'Tipo de Ocorrência',
            sortable: true,
            renderCell: (rowData) => translateOccurrenceType(rowData.occurrence_type) || 'Não informado'
        },
        {
            field: 'police_report_number',
            headerName: 'Número B.O.',
            renderCell: (rowData) => rowData.police_report_number || 'Não informado',
            sortable: true
        },
        {
            field: 'occurrence_date',
            headerName: 'Data',
            renderCell: (rowData) => {
                try {
                    const date = rowData.occurrence_date;
                    return date ? new Intl.DateTimeFormat('pt-BR').format(new Date(date)) : 'Não informado';
                } catch (error) {
                    console.error('Erro ao formatar data:', error);
                    return 'Data inválida';
                }
            },
            sortable: true
        },
        {
            field: 'occurrence_time',
            headerName: 'Hora',
            renderCell: (rowData) => {
                try {
                    const time = rowData.occurrence_time;
                    return time ? time.substring(0, 5) : 'Não informado'; // Pega só HH:mm
                } catch (error) {
                    console.error('Erro ao formatar hora:', error);
                    return 'Hora inválida';
                }
            },
            sortable: true
        },
        {
            field: 'property',
            headerName: 'Propriedade',
            renderCell: (rowData) => rowData.property?.name || 'Não informado',
            sortable: true
        },
        {
            field: 'municipality',
            headerName: 'Município',
            sortable: true,
            renderCell: (rowData) => rowData.municipality || 'Não informado'
        },
        {
            field: 'patrol',
            headerName: 'Batalhão',
            renderCell: (rowData) => rowData.property?.patrol?.name || 'Sem Batalhão',
            sortable: true
        },
        {
            field: 'actions',
            headerName: 'Ações',
            renderCell: (rowData) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                        e.stopPropagation();
                        setModal({
                            type: 'assignOccurrence',
                            data: rowData
                        });
                    }}
                >
                    Atribuir Ocorrência
                </Button>
            ),
            sortable: false
        }
    ];

    const handlePatrols = async () => {
        try {
            const response = await Read();
            if (response?.data) {
                const formattedPatrols = response.data.map(patrol => ({
                    id: patrol.id,
                    name: patrol.attributes.name,
                    code: patrol.attributes.code,
                    license_plate: patrol.attributes.license_plate
                }));
                setPatrols(formattedPatrols);
            }
        } catch (error) {
            console.error("Erro ao buscar batalhes:", error);
        }
    };

    const handleRowClick = (rowData) => {
        setSelectedProduct(rowData);
        if (rowData.status?.toLowerCase() === 'aguardando') {
            setModal({
                type: 'chooseAction',
                data: rowData
            });
        }
    };

    const propertiesWithOccurrences = processedData?.filter(
        property => property.occurrences?.length > 0
    ) || [];

    // Prepara os dados combinados para o mapa
    const mapProperties = React.useMemo(() => {
        const propertyMarkers = (filteredData?.length > 0 ? filteredData : processedData)?.map(property => ({
            ...property,
            type: 'property'
        })) || [];

        const officerMarkers = officers
            .filter(officer => {
                return officer.location
            })
            .map(officer => ({
                id: `officer-${officer.id}`,
                name: `${officer.patrol.name} (${officer.patrol.license_plate})`,
                lat: officer.location.latitude,
                long: officer.location.longitude,
                type: 'officer',
                status: officer.location && Date.now() - new Date(officer.location.createdAt).getTime() < 300000 ? 'ativo' : 'inativo',
                icon: <PoliceCar size={24} weight="duotone" />,
                officer: {
                    name: officer.name,
                    funcional: officer.funcional
                },
                battery: officer.location.battery_level,
                isCharging: officer.location.is_charging,
                connection: officer.location.connection_type,
                lastUpdate: officer.lastUpdate ? new Intl.DateTimeFormat('pt-BR', {
                    dateStyle: 'short',
                    timeStyle: 'short'
                }).format(officer.lastUpdate) : 'Nunca'
            }));

        return [...propertyMarkers, ...officerMarkers];
    }, [filteredData, processedData, officers]);

    const toggleFullscreen = (section) => {
        setFullscreenSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const renderSectionTitle = (title, section) => (
        <TitleContainer>
            <TitleWrapper>
                <PageTitle>{title}</PageTitle>
                <FullscreenButton onClick={() => toggleFullscreen(section)}>
                    {fullscreenSections[section] ? (
                        <ArrowsIn size={24} weight="duotone" />
                    ) : (
                        <ArrowsOut size={24} weight="duotone" />
                    )}
                </FullscreenButton>
            </TitleWrapper>
        </TitleContainer>
    );

    // Função para carregar propriedades em andamento
    const loadPropertiesInProgress = async () => {
        try {
            const response = await GetPropertiesInProgress();
            if (response?.data) {
                setPropertiesInProgress(response.data);
            }
        } catch (error) {
            console.error('Erro ao carregar propriedades em andamento:', error);
            setModal({
                type: 'warning',
                title: 'Erro',
                text: 'Não foi possível carregar as propriedades em andamento.'
            });
        }
    };

    // Função para carregar ocorrências em andamento
    const loadOccurrencesInProgress = async () => {
        try {
            const response = await GetOccurrencesInProgress();
            if (response?.data) {
                setOccurrencesInProgress(response.data);
            }
        } catch (error) {
            console.error('Erro ao carregar ocorrências em andamento:', error);
            setModal({
                type: 'warning',
                title: 'Erro',
                text: 'Não foi possível carregar as ocorrências em andamento.'
            });
        }
    };

    // Atualize o useEffect para carregar ambos os dados
    useEffect(() => {
        loadPropertiesInProgress();
        loadOccurrencesInProgress();
    }, []);

    useEffect(() => {
        const loadData = async () => {
            try {
                setIsLoading(true);
                const response = await getUnassignedOccurrences();
                console.log("🚀 ~ loadData ~ response:", response)
                if (response?.data) {
                    setPendingOccurrences(response.data);
                }
            } catch (error) {
                console.error('Erro ao carregar ocorrências:', error);
                setModal({
                    type: 'warning',
                    title: 'Erro',
                    text: 'Não foi possível carregar as ocorrências.'
                });
            } finally {
                setIsLoading(false);
            }
        };

        loadData();
    }, []);

    return (
        <ContentContainer>
            {/* Seção de Cards de Propriedades */}
            <SectionFullscreen isFullscreen={fullscreenSections.propertyCards}>
                {renderSectionTitle('Propriedades', 'propertyCards')}
                <Wrapper>
                    <StatisticCard title="Total" value={totals.total} />
                    <StatisticCard title="Reprovadas" value={totals.reprovadas} />
                    <StatisticCard title="Visitadas" value={totals.visitadas} />
                    <StatisticCard title="Aguardando" value={totals.aguardando} />
                    <StatisticCard title="Aprovadas" value={totals.aprovadas} />
                    <StatisticCard title="Inativas" value={totals.inativas} />
                </Wrapper>
            </SectionFullscreen>

            {/* Seção de Tabelas (Aguardando e Ocorrências) */}
            <SectionFullscreen isFullscreen={fullscreenSections.tables}>
                {renderSectionTitle('Tabelas de Status', 'tables')}
                <WrapperTablles>
                    <div>
                        <TableTitle>Propriedades Aguardando</TableTitle>
                        <ModernTable
                            scroll={true}
                            columns={aguardandoColumns}
                            data={aguardandoData}
                            pagination={true}
                            rowsPerPageOptions={[5, 10, 25]}
                            loading={isLoading}
                        />
                    </div>

                    <div>
                        <TableTitle>Ocorrências Pendentes</TableTitle>
                        <ModernTable
                            columns={occurrenceColumns}
                            data={pendingOccurrences}
                            pagination={true}
                            rowsPerPageOptions={[5, 10, 25]}
                            loading={isLoading}
                            scroll={true}
                        />
                    </div>
                </WrapperTablles>

                <WrapperTablles style={{ marginTop: '90px' }}>
                    <div>
                        <TableTitle>Visitas em Andamento</TableTitle>
                        <ModernTable
                            scroll={true}
                            columns={inProgressColumns}
                            data={propertiesInProgress}
                            pagination={true}
                            rowsPerPageOptions={[5, 10, 25]}
                            loading={isLoading}
                        />
                    </div>

                    <div>
                        <TableTitle>Ocorrências em Andamento</TableTitle>
                        <ModernTable
                            scroll={true}
                            columns={occurrencesInProgressColumns}
                            data={occurrencesInProgress}
                            pagination={true}
                            rowsPerPageOptions={[5, 10, 25]}
                            loading={isLoading}
                        />
                    </div>
                </WrapperTablles>
            </SectionFullscreen>

            {/* Seção Lista de Propriedades */}
            <SectionFullscreen isFullscreen={fullscreenSections.propertyList}>
                {renderSectionTitle('Lista de Propriedades', 'propertyList')}
                <ModernTable
                    columns={columns}
                    data={filteredData?.length > 0 ? filteredData : processedData}
                    pagination={true}
                    onRowClick={handleRowClick}
                    rowsPerPageOptions={[5, 10, 25]}
                    loading={isLoading}
                />
            </SectionFullscreen>

            {/* Seção Mapa */}
            <SectionFullscreen isFullscreen={fullscreenSections.map}>
                {renderSectionTitle('Localização das Propriedades e Viaturas', 'map')}
                <ModernMap
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    properties={mapProperties}
                />
            </SectionFullscreen>

            {/* Seção Estatísticas por Batalhão */}
            <SectionFullscreen isFullscreen={fullscreenSections.patrolStats}>
                {renderSectionTitle('Estatísticas por Batalhão', 'patrolStats')}

                {/* Carousel para visualização normal */}
                <div className="carousel-container">
                    <Carousel sx={{ mb: 4 }}>
                        {patrols.map((patrol) => (
                            <PatrolStatisticCard
                                key={patrol.id}
                                patrol={patrol}
                                stats={patrolStats[patrol.name] || {
                                    total: 0,
                                    aprovadas: 0,
                                    reprovadas: 0,
                                    visitadas: 0,
                                    aguardando: 0,
                                    inativas: 0
                                }}
                            />
                        ))}
                    </Carousel>
                </div>

                {/* Grid para visualização em fullscreen */}
                <div className="grid-container">
                    <PatrolStatsGrid>
                        {patrols.map((patrol) => (
                            <PatrolStatisticCard
                                key={patrol.id}
                                patrol={patrol}
                                stats={patrolStats[patrol.name] || {
                                    total: 0,
                                    aprovadas: 0,
                                    reprovadas: 0,
                                    visitadas: 0,
                                    aguardando: 0,
                                    inativas: 0
                                }}
                            />
                        ))}
                    </PatrolStatsGrid>
                </div>
            </SectionFullscreen>

            {/* Seção Análise de Ocorrências */}
            <SectionFullscreen isFullscreen={fullscreenSections.occurrenceAnalysis}>
                {renderSectionTitle('Análise de Ocorrências', 'occurrenceAnalysis')}
                <ChartGrid>
                    <ChartPaper>
                        <BarChart
                            data={chartData.bar}
                            title="Ocorrências por Município"
                            loading={loading}
                        />
                    </ChartPaper>
                    <ChartPaper>
                        <DonutChart
                            data={chartData.donut}
                            title="Distribuição de Ocorrências"
                            loading={loading}
                        />
                    </ChartPaper>
                </ChartGrid>
            </SectionFullscreen>

            {/* Seção Totalizadores */}
            {/* <SectionFullscreen isFullscreen={fullscreenSections.occurrenceStats}>
                {renderSectionTitle('Totalizadores por Tipo de Ocorrência', 'occurrenceStats')}
                <OccurrenceTypeStats properties={processedData} />
            </SectionFullscreen> */}
        </ContentContainer>
    );
};

export default HomePage;
