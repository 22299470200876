import { POST, GET, PUT } from "./api";

export const Create = async (data) => {
  const body = {
    data: {
      officer: data.officerId,
      occurrence: data.occurrence || null,
      property: data.property || null,
      status: 'active'
    }
  };

  return await POST('/officer-assignments', body, true);
};

export const GetByOfficer = async (officerId) => {
  return await GET(`/officer-assignments?filters[officer][id][$eq]=${officerId}&populate=*`);
};

export const UpdateStatus = async (assignmentId, status) => {
  return await PUT(`/officer-assignments/${assignmentId}`, {
    data: {
      status
    }
  });
};

// Função para obter propriedades em andamento
export const GetPropertiesInProgress = async () => {
  return await GET('/officer-assignments/properties-in-progress', true);
};

// Função para obter ocorrências em andamento
export const GetOccurrencesInProgress = async () => {
  return await GET('/officer-assignments/occurrences-in-progress', true);
}; 