import React, { useContext, useState, useEffect } from 'react';
import { Container, ButtonContainer, Section, OfficerCard } from './styled';
import Wrapper from '../Wrapper';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import { Button, Typography, CircularProgress } from '@mui/material';
import { GetAll as GetOfficers } from '../../../service/officer';
import { Create as CreateAssignment } from '../../../service/officer-assignments';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { PoliceCar } from '@phosphor-icons/react';

export default function ModalAssignOrders() {
  const theme = useTheme();
  const { modal, setModal } = useContext(AuthContext);
  const [officers, setOfficers] = useState([]);
  const [selectedOfficer, setSelectedOfficer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const close = () => {
    setModal(null);
  };

  const handleSave = async () => {
    if (!showConfirm) {
      setShowConfirm(true);
      return;
    }

    try {
      setLoading(true);

      const result = await CreateAssignment({
        officerId: selectedOfficer.id,
        property: modal.data.id
      });

      if (result.error) throw new Error(result.error);

      toast.success('Propriedade atribuída com sucesso');
      close();
    } catch (error) {
      console.error('Erro ao atribuir propriedade:', error);
      toast.error('Erro ao atribuir propriedade');
    } finally {
      setLoading(false);
    }
  };

  const loadOfficers = async () => {
    try {
      setLoading(true);
      const response = await GetOfficers();

      if (response?.data) {
        const formattedOfficers = response.data.map(officer => ({
          id: officer.id,
          name: officer.attributes.user.data.attributes.username,
          funcional: officer.attributes.funcional,
          patrol: officer.attributes.patrol.data?.attributes
        }));
        setOfficers(formattedOfficers);
      }
    } catch (error) {
      console.error('Erro ao carregar policiais:', error);
      toast.error('Erro ao carregar policiais');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadOfficers();
  }, []);

  const handleSelectOfficer = (officer) => {
    setSelectedOfficer(officer);
    setShowConfirm(false);
  };

  return (
    <Wrapper title="Atribuir Propriedade a Policial">
      <Container>
        <Section>
          <Typography variant="subtitle1" sx={{ color: theme.palette.primary.light, mb: 2 }}>
            Propriedade Selecionada: {modal.data.name}
          </Typography>

          {showConfirm ? (
            <>
              <Typography variant="h6" sx={{ color: theme.palette.primary.light }}>
                Confirmar Atribuição
              </Typography>
              <Typography sx={{ color: theme.palette.text.primary, mt: 1 }}>
                Você está prestes a atribuir a propriedade:
              </Typography>
              <Typography sx={{ color: theme.palette.text.primary, mt: 1, fontWeight: 'bold' }}>
                {modal.data.name}
              </Typography>
              <Typography sx={{ color: theme.palette.text.primary, mt: 1 }}>
                Para o policial:
              </Typography>
              <Typography sx={{ color: theme.palette.text.primary, mt: 1, fontWeight: 'bold' }}>
                {selectedOfficer.name} (Funcional: {selectedOfficer.funcional})
              </Typography>
              <Typography sx={{ color: theme.palette.text.primary, mt: 1 }}>
                Deseja continuar?
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h6" sx={{ color: theme.palette.primary.light, mb: 2 }}>
                Selecione um Policial
              </Typography>
              
              {loading ? (
                <CircularProgress size={24} sx={{ color: theme.palette.primary.light }} />
              ) : officers.length === 0 ? (
                <Typography sx={{ color: theme.palette.primary.light }}>
                  Nenhum policial disponível
                </Typography>
              ) : (
                <div style={{ display: 'grid', gap: '16px' }}>
                  {officers.map((officer) => (
                    <OfficerCard
                      key={officer.id}
                      selected={selectedOfficer?.id === officer.id}
                      onClick={() => handleSelectOfficer(officer)}
                    >
                      <div className="icon">
                        <PoliceCar size={24} weight="duotone" />
                      </div>
                      <div className="info">
                        <Typography variant="subtitle1">{officer.name}</Typography>
                        <Typography variant="body2">Funcional: {officer.funcional}</Typography>
                        {officer.patrol && (
                          <Typography variant="body2">
                            Batalhão: {officer.patrol.name}
                          </Typography>
                        )}
                      </div>
                    </OfficerCard>
                  ))}
                </div>
              )}
            </>
          )}
        </Section>

        <ButtonContainer>
          <Button
            variant="contained"
            sx={{
              bgcolor: theme.palette.button.primary,
              '&:hover': {
                bgcolor: theme.palette.button.hover,
              },
              '&:disabled': {
                bgcolor: theme.palette.button.disabled,
              }
            }}
            onClick={handleSave}
            disabled={loading || !selectedOfficer}
            startIcon={loading && <CircularProgress size={20} color="inherit" />}
          >
            {loading ? 'Salvando...' : showConfirm ? 'Confirmar' : 'Atribuir'}
          </Button>
          <Button
            variant="outlined"
            onClick={showConfirm ? () => setShowConfirm(false) : close}
            disabled={loading}
            sx={{
              color: loading ? theme.palette.text.disabled : theme.palette.primary.light,
              borderColor: loading ? theme.palette.text.disabled : theme.palette.primary.light,
              '&:hover': {
                borderColor: theme.palette.primary.light,
                bgcolor: 'transparent'
              }
            }}
          >
            {showConfirm ? 'Voltar' : 'Cancelar'}
          </Button>
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
} 