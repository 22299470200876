import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    min-height: calc(100vh - 64px);
    background-color: ${({ theme }) => theme.palette.background.default};
`;

export const Form = styled.form`
`;

export const Title = styled.h1`
    text-align: center;
    color: ${({ theme }) => theme.palette.primary.light};
    margin-bottom: 3rem;
    font-size: 2rem;
    font-weight: 600;
`;

export const FormSection = styled.div`
    margin-bottom: 1.5rem;
`;

export const Label = styled.label`
    display: block;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.palette.primary.light};
    font-size: 1rem;
    font-weight: 500;
`;

export const Input = styled.input`
    width: 100%;
    padding: 1rem;
    background-color: ${({ theme }) => theme.palette.background.input};
    border: 2px solid ${({ theme }) => theme.palette.input.border.default};
    border-radius: 8px;
    font-size: 1rem;
    color: ${({ theme }) => theme.palette.input.text};
    transition: all 0.2s ease-in-out;

    &:focus {
        outline: none;
        border-color: ${({ theme }) => theme.palette.input.border.focus};
        box-shadow: 0 0 0 3px ${({ theme }) => theme.palette.primary.main}33;
    }

    &::placeholder {
        color: ${({ theme }) => theme.palette.text.disabled};
    }
`;

export const Select = styled.select`
    width: 100%;
    padding: 1rem;
    background-color: ${({ theme }) => theme.palette.background.input};
    border: 2px solid ${({ theme }) => theme.palette.input.border.default};
    border-radius: 8px;
    font-size: 1rem;
    color: ${({ theme }) => theme.palette.input.text};
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:focus {
        outline: none;
        border-color: ${({ theme }) => theme.palette.input.border.focus};
        box-shadow: 0 0 0 3px ${({ theme }) => theme.palette.primary.main}33;
    }

    option {
        background-color: ${({ theme }) => theme.palette.background.paper};
        color: ${({ theme }) => theme.palette.text.primary};
        padding: 1rem;
    }
`;

export const Button = styled.button`
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
    background-color: ${({ theme }) => theme.palette.button.primary};
    color: ${({ theme }) => theme.palette.text.primary};
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: ${({ theme }) => theme.palette.button.hover};
        transform: translateY(-1px);
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.button.active};
        transform: translateY(0);
    }

    &:disabled {
        background-color: ${({ theme }) => theme.palette.button.disabled};
        color: ${({ theme }) => theme.palette.text.disabled};
        cursor: not-allowed;
        transform: none;
    }
`;

export const ErrorMessage = styled.p`
    color: ${({ theme }) => theme.palette.input.border.error};
    text-align: center;
    margin: 1rem 0;
    font-size: 0.9rem;
    font-weight: 500;
`; 