import { useContext } from 'react';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import Wrapper from '../Wrapper';
import {
    InfoContainer,
    ImageContainer,
    PropertyImage,
    InfoSection,
    InfoRow,
    Label,
    Value,
    NoImageContainer,
    MarkerIcon,
    LocationButton
} from './styled';
import { MapPin, MapTrifold } from '@phosphor-icons/react';

const PropertyInfoModal = () => {
    const { modal, setModal, setSelectedProduct, setMapCenter } = useContext(AuthContext);
    const property = modal?.data;

    const handleShowOnMap = () => {
        if (property.lat && property.long) {
            setMapCenter({ lat: property.lat, lng: property.long });
            setSelectedProduct(property);
            setModal(null);
        }
    };

    if (!property) return null;

    return (
        <Wrapper title="Informações da Propriedade">
            <InfoContainer>
                {property.photo?.url ? (
                    <ImageContainer>
                        <PropertyImage 
                            src={property.photo.url} 
                            alt="Imagem da propriedade" 
                        />
                    </ImageContainer>
                ) : (
                    <NoImageContainer>
                        <MapPin size={48} weight="duotone" />
                        <span>Sem imagem disponível</span>
                    </NoImageContainer>
                )}

                <InfoSection>
                    <InfoRow>
                        <Label>Nome da Propriedade:</Label>
                        <Value>{property.name || 'Não informado'}</Value>
                    </InfoRow>
                    <InfoRow>
                        <Label>Proprietário:</Label>
                        <Value>{property.proprietario || 'Não informado'}</Value>
                    </InfoRow>
                    <InfoRow>
                        <Label>Número da Propriedade:</Label>
                        <Value>{property.property_number || 'Não informado'}</Value>
                    </InfoRow>
                    <InfoRow>
                        <Label>Área:</Label>
                        <Value>{property.area || 'Não informada'}</Value>
                    </InfoRow>
                    <InfoRow>
                        <Label>Endereço:</Label>
                        <Value>{property.municipality || 'Não informado'}</Value>
                    </InfoRow>
                    <InfoRow>
                        <Label>Linha:</Label>
                        <Value>{property.line || 'Não informada'}</Value>
                    </InfoRow>
                    <InfoRow>
                        <Label>Estado:</Label>
                        <Value>{property.state || 'Não informado'}</Value>
                    </InfoRow>
                    <InfoRow>
                        <Label>Status:</Label>
                        <Value>{property.status || 'Não informado'}</Value>
                    </InfoRow>
                    <InfoRow>
                        <Label>Status de Visita:</Label>
                        <Value>{property.status_visit || 'Não informado'}</Value>
                    </InfoRow>
                    <InfoRow>
                        <Label>Observação:</Label>
                        <Value>{property.observacao || 'Sem observações'}</Value>
                    </InfoRow>
                    <InfoRow>
                        <Label>Localização:</Label>
                        <Value>
                            <MarkerIcon weight="duotone" />
                            Lat: {property.lat}, Long: {property.long}
                            <LocationButton
                                variant="contained"
                                onClick={handleShowOnMap}
                                startIcon={<MapTrifold weight="duotone" />}
                            >
                                Ver no Mapa
                            </LocationButton>
                        </Value>
                    </InfoRow>
                </InfoSection>
            </InfoContainer>
        </Wrapper>
    );
};

export default PropertyInfoModal; 