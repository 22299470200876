import React from 'react';
import { CloseContainer, CompanyDataContainer, Title, WrapperTitleAndCloseIcon } from './styled';
import { Icon, ModalContainer, ModalContent, Overlay } from '../../../ui/styled';
import { useContext } from 'react';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import styled from 'styled-components';

export default function Wrapper({ children, title, width }) {
    const { setModal } = useContext(AuthContext);

    const close = () => {
        setModal(null);
    };

    const handleClose = (e) => {
        const mc = document.getElementById('modal-content');
        if (mc && !mc.contains(e.target)) {
            close();
        }
    };

    return (
        <Overlay onClick={handleClose}>
            <ModalContainer>
                <ModalContent width={width} id="modal-content">
                    <CompanyDataContainer>
                        <WrapperTitleAndCloseIcon>
                            <Title>{title}</Title>
                            <CloseContainer>
                                <Icon icon={'close-white'} pointer={true} onClick={close} />
                            </CloseContainer>
                        </WrapperTitleAndCloseIcon>
                        {children}
                    </CompanyDataContainer>
                </ModalContent>
            </ModalContainer>
        </Overlay>
    );
}
