import { useEffect, useState, useContext } from "react";
import { GetAll } from "../../../service/officer";
import { Container, HeaderContainer, ActionsContainer } from "./styled";
import ModernTable from "../../../components/Tables/ModernTable";
import { AuthContext } from "../../../context/contextGlobal/authContext";
import { Typography, IconButton, Tooltip, Menu, MenuItem } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Plus, DotsThreeVertical } from 'phosphor-react';
import { toast } from "react-toastify";

export default function Team() {
  const theme = useTheme();
  const [officers, setOfficers] = useState([]);
  const { setModal, modal } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOfficer, setSelectedOfficer] = useState(null);

  const handleMenuClick = (event, officer) => {
    event.preventDefault();
    event.stopPropagation();
    console.log('📱 Menu clicado para o policial:', officer);
    setAnchorEl(event.currentTarget);
    setSelectedOfficer(officer);
  };

  const handleMenuClose = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    console.log('🔒 Menu fechado');
    setAnchorEl(null);
    setSelectedOfficer(null);
  };

  const handleEdit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log('✏️ Editando policial:', selectedOfficer);
    setModal({
      type: 'editOfficer',
      data: selectedOfficer
    });
    handleMenuClose();
  };

  const handleMove = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log('🔄 Movendo policial:', selectedOfficer);
    setModal({
      type: 'moveOfficer',
      data: selectedOfficer
    });
    handleMenuClose();
  };

  const columns = [
    { field: 'funcional', headerName: 'Funcional', sortable: true },
    { field: 'status', headerName: 'Status', sortable: true },
    {
      field: 'userName',
      headerName: 'Nome do Policial',
      sortable: true,
      renderCell: (row) => row.user?.name || '-'
    },
    {
      field: 'patrol',
      headerName: 'Batalhão',
      sortable: true,
      renderCell: (row) => row.patrol?.name || '-'
    },
    {
      field: 'actions',
      headerName: 'Ações',
      sortable: false,
      renderCell: (row) => (
        <ActionsContainer onClick={e => e.stopPropagation()}>
          <Tooltip title="Menu de ações">
            <IconButton
              size="small"
              onClick={(e) => handleMenuClick(e, row)}
              sx={{ color: theme.palette.primary.light }}
            >
              <DotsThreeVertical size={20} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && selectedOfficer?.id === row.id}
            onClose={handleMenuClose}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            MenuListProps={{
              onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          >
            <MenuItem onClick={handleEdit}>Editar</MenuItem>
            <MenuItem onClick={handleMove}>Mover</MenuItem>
          </Menu>
        </ActionsContainer>
      )
    }
  ];

  const handleAdd = () => {
    setModal({
      type: 'registerOfficer',
      data: null
    });
  };

  const init = async () => {
    const result = await GetAll();

    if (result.error) {
      console.error('❌ Erro ao carregar lista:', result.error);
      toast.error('Erro ao carregar lista de policiais');
    } else {
      const formattedData = result.data.map(officer => ({
        id: officer.id,
        funcional: officer.attributes.funcional,
        status: officer.attributes.status === 'active' ? 'Ativo'
          : officer.attributes.status === 'inactive' ? 'Inativo'
            : officer.attributes.status === 'onLeave' ? 'De Licença'
              : '-',
        user: officer.attributes.user?.data?.attributes,
        patrol: officer.attributes.patrol?.data?.attributes
      }));
      console.log('📋 Lista carregada:', formattedData);
      setOfficers(formattedData);
    }
  }

  // Carrega dados iniciais
  useEffect(() => {
    init();
  }, []);

  // Monitora mudanças no modal
  useEffect(() => {
    console.log('🔄 Modal mudou:', modal);
    // Se o modal foi fechado, atualiza a lista
    if (!modal) {
      init();
    }
  }, [modal]);

  return (
    <Container>
      <HeaderContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" sx={{ color: theme.palette.primary.light, fontWeight: 600 }}>
            GESTÃO DE EQUIPE
          </Typography>
          <Tooltip title="Adicionar policial">
            <IconButton
              onClick={handleAdd}
              sx={{
                color: theme.palette.primary.light,
                backgroundColor: theme.palette.primary.dark,
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                }
              }}
            >
              <Plus size={20} weight="bold" />
            </IconButton>
          </Tooltip>
        </div>
      </HeaderContainer>

      <ModernTable
        columns={columns}
        data={officers}
        pagination={true}
        rowsPerPageOptions={[5, 10, 25]}
        loading={false}
        scroll={true}
      />
    </Container>
  )
}