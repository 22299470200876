import styled from 'styled-components';

export const Container = styled.div`
    padding: 24px;
    width: 100%;
    max-width: 500px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const FormRow = styled.div`
    width: 100%;
`; 