import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import PrivateRoute from "./privateRoutes";
import LoginPage from "../pages/public/loginPage";
import HomePage from "../pages/auth/homePage";
import Layout from "../components/Layout/index";
import ActiveUsersList from "../pages/auth/ActiveUsersList/ActiveUsersList";
import OrdersService from "../pages/auth/OrdersService";

const Navigator = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route
                    path="/"
                    element={
                        <PrivateRoute>
                            <Layout />
                        </PrivateRoute>
                    }
                >
                    <Route path="home" element={<HomePage />} />
                    <Route path="activeUsersList" element={<ActiveUsersList />} />
                    <Route path="orders-service" element={<OrdersService />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Navigator;
