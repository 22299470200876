import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* padding: 24px 0; */
  max-height: 70vh;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
`;

export const OfficerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background: ${props => props.theme.palette.background.paper};
  border: 1px solid ${props => props.theme.palette.colors.border};
  border-radius: 8px;
`;

export const Section = styled.div`
  background: ${props => props.theme.palette.background.paper};
  border: 1px solid ${props => props.theme.palette.colors.border};
  border-radius: 8px;
  padding: 16px;

  h6 {
    color: ${props => props.theme.palette.primary.light};
    margin-bottom: 16px;
    font-weight: 600;
  }

  .MuiFormGroup-root {
    gap: 8px;
  }
    
  .MuiFormControlLabel-root {
    margin: 0;
    
    .MuiCheckbox-root {
      color: ${props => props.theme.palette.primary.main};
      
      &.Mui-checked {
        color: ${props => props.theme.palette.primary.light};
    }
    
      &.Mui-disabled {
        color: ${props => props.theme.palette.text.disabled};
      }
    }
    
    .MuiFormControlLabel-label {
      color: ${props => props.theme.palette.text.primary};
      
      &.Mui-disabled {
        color: ${props => props.theme.palette.text.disabled};
      }
    }
  }
`;

export const OfficerCard = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background: ${props => props.selected ? props.theme.palette.action.selected : props.theme.palette.background.paper};
  border: 1px solid ${props => props.selected ? props.theme.palette.primary.light : props.theme.palette.colors.border};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: ${props => props.theme.palette.primary.light};
    background: ${props => props.theme.palette.action.hover};
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${props => props.theme.palette.primary.main}20;
    color: ${props => props.theme.palette.primary.light};
  }

  .info {
    flex: 1;
    
    p {
      color: ${props => props.theme.palette.text.primary};
      
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 8px;
  
  button {
    min-width: 120px;
  }
`; 