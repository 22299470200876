import { GET } from './api';

export const getOccurrencesByRegion = async (lat, long, radius = 5) => {
  try {
    const response = await GET('/occurrences/region', true, {
      params: { lat, long, radius }
    });
    return response;
  } catch (error) {
    console.error('Erro ao buscar ocorrências por região:', error);
    throw error;
  }
};

export const getOccurrenceStatistics = async () => {
  try {
    const response = await GET('/occurrences/statistics', true);
    return response;
  } catch (error) {
    console.error('Erro ao buscar estatísticas de ocorrências:', error);
    throw error;
  }
};

export const getAllOccurrences = async () => {
  try {
    const response = await GET('/occurrences', true, {
      params: {
        populate: '*',  // Para garantir que pegue todos os relacionamentos
        'filters[publishedAt][$ne]': null, // Apenas ocorrências publicadas
      }
    });

    // Processa os dados para estatísticas de município
    const occurrences = response.data || [];
    
    // Mapeia os dados considerando a estrutura do Strapi
    const municipalityData = occurrences.map(occurrence => ({
      municipality: occurrence.attributes.municipality || 'Não informado',
      // outros dados que precisarmos
    }));

    // Conta ocorrências por município
    const municipalityCount = municipalityData.reduce((acc, { municipality }) => {
      acc[municipality] = (acc[municipality] || 0) + 1;
      return acc;
    }, {});

    // Calcula as porcentagens
    const total = Object.values(municipalityCount).reduce((sum, count) => sum + count, 0);
    const distribution = Object.entries(municipalityCount).map(([municipality, count]) => ({
      municipality,
      count,
      percentage: ((count / total) * 100).toFixed(1)
    }));

    // Ordena por contagem e pega os top 5
    const topMunicipalities = distribution
      .sort((a, b) => b.count - a.count)
      .slice(0, 5);

    return {
      municipalityStats: topMunicipalities,
      distribution: topMunicipalities
    };
  } catch (error) {
    console.error('Erro ao buscar ocorrências:', error);
    throw error;
  }
};

export const GetAll = async () => {
  return await GET('/occurrences', true);
};

export const GetAvailable = async () => {
  return await GET('/occurrences/available', true);
};

export const getUnassignedOccurrences = async () => {
  try {
    const response = await GET('/occurrences/unassigned', true, {
      params: {
        populate: ['property', 'property.patrol']
      }
    });
    return response;
  } catch (error) {
    console.error('Erro ao buscar ocorrências não atribuídas:', error);
    throw error;
  }
}; 