import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import { Container, Form, FormRow } from './styled';
import { Button, TextField, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import { ReadAll } from '../../../service/patrols';
import { Update } from '../../../service/officer';
import Wrapper from '../Wrapper';

export default function MoveOfficer() {
    const { setModal, modal } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [patrols, setPatrols] = useState([]);
    const [formData, setFormData] = useState({
        id: modal?.data?.id || '',
        patrolId: modal?.data?.patrol?.id || '',
    });

    // Atualiza formData quando modal.data mudar
    useEffect(() => {
        if (modal?.data) {
            setFormData({
                id: modal.data.id || '',
                patrolId: modal.data.patrol?.id || '',
            });
        }
    }, [modal?.data]);

    useEffect(() => {
        const loadPatrols = async () => {
            try {
                const response = await ReadAll();
                if (response?.data) {
                    const formattedPatrols = response.data.map(patrol => ({
                        id: patrol.id,
                        ...patrol.attributes
                    }));
                    setPatrols(formattedPatrols);
                }
            } catch (error) {
                console.error('❌ Erro ao carregar batalhões:', error);
                toast.error('Erro ao carregar batalhões');
            }
        };

        loadPatrols();
    }, []);

    const handleChange = (field) => (event) => {
        event.preventDefault();
        event.stopPropagation();
        setFormData(prev => ({
            ...prev,
            [field]: event.target.value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);

        try {
            // Formatando os dados no formato que o Strapi espera
            const payload = {
                data: {
                    patrol: formData.patrolId
                }
            };

            const response = await Update({
                id: formData.id,
                data: payload
            });

            if (response.error) {
                console.error('❌ Erro na resposta:', response.error);
                toast.error('Erro ao mover policial');
                return;
            }

            toast.success('Policial movido com sucesso');
            setModal(null);
        } catch (error) {
            console.error('❌ Erro ao mover policial:', error);
            toast.error('Erro ao mover policial');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Wrapper title='Mover Policial para Novo Batalhão'>
            <Container>
                <Form onSubmit={handleSubmit} onClick={e => e.stopPropagation()}>
                    <FormRow>
                        <TextField
                            select
                            label="Novo Batalhão"
                            value={formData.patrolId}
                            onChange={handleChange('patrolId')}
                            fullWidth
                            required
                            onClick={e => e.stopPropagation()}
                        >
                            {patrols.map(patrol => (
                                <MenuItem
                                    key={patrol.id}
                                    value={patrol.id}
                                    onClick={e => e.stopPropagation()}
                                >
                                    {patrol.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormRow>

                    <FormRow>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                            disabled={loading}
                        >
                            Mover Policial
                        </Button>
                    </FormRow>
                </Form>
            </Container>
        </Wrapper>
    );
} 