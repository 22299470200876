import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
    background-color: ${({ theme }) => theme.palette.dark};
    border-radius: 8px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
`;

export const FormRow = styled.div`
    width: 100%;
    
    .MuiInputBase-root {
        background-color: ${({ theme }) => theme.palette.background.default};
    }

    .MuiInputLabel-root {
        color: ${({ theme }) => theme.palette.text.primary};
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.divider};
    }
`; 